<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <el-icon>
            <menu />
          </el-icon>
          Subscription List
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-input
          v-model="query.studentId"
          placeholder="Student No."
          class="handle-input mr10"
          @keyup.enter="handleSearch"
          :clearable="true"
        ></el-input>
        <el-input
          v-model="query.studentName"
          placeholder="Student Name"
          class="handle-input mr10"
          @keyup.enter="handleSearch"
          :clearable="true"
        ></el-input>
        <el-input
          v-model="query.objectDescription"
          placeholder="Channel Name"
          class="handle-input mr10"
          @keyup.enter="handleSearch"
          :clearable="true"
        ></el-input>
        <el-button type="primary" @click="handleSearch">
          <el-icon>
            <search />
          </el-icon>
          Search
        </el-button>
      </div>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @sort-change="changeSort"
        :default-sort="{ prop: 'CreatedTime', order: 'ascending' }"
      >
        <el-table-column
          prop="subscriptionId"
          label="SubscriptionId"
          :width="this.$widthRatio * 200 + 'px'"
        ></el-table-column>
        <el-table-column
          prop="studentId"
          label="Student No."
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="StudentId"
          :width="this.$widthRatio * 108 + 'px'"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="studentName"
          label="Student Name"
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="StudentName"
          :width="this.$widthRatio * 140 + 'px'"
        ></el-table-column>
        <el-table-column prop="objectDescription" label="Channel Name"></el-table-column>
        <el-table-column
          prop="status"
          label="Status"
          :width="this.$widthRatio * 100 + 'px'"
        ></el-table-column>
        <el-table-column
          prop="updatedTime"
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="UpdatedTime"
          label="Updated Time"
          :width="this.$widthRatio * 120 + 'px'"
          :formatter="$tableCellFormatter.formatDate"
        ></el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="itemTotal"
          :page-count="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getData } from "../../service/api";

export default {
  data() {
    return {
      controllerUrl: "/subscription",
      query: {
        studentId: null,
        studentName: null,
        objectDescription: null,
        orderBy: "CreatedTime",
        orderDirection: "ASC",
        pageIndex: 1,
        pageSize: 20,
      },
      options: {
        gradeList: [],
      },
      tableData: [],
      dialogTitle: "",
      editVisible: false,
      itemTotal: 0,
      pageTotal: 0,
      form: {},
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      getData(this.controllerUrl, this.query).then((res) => {
        console.log(res);

        let resData = res.result;
        this.tableData = resData.list;
        this.query.pageIndex = resData.pageIndex;
        this.query.pageSize = resData.pageSize;
        this.itemTotal = resData.count;
        this.pageTotal = Math.ceil(resData.count / resData.pageSize);
      });
    },
    handleSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    changeSort(sortData) {
      if (sortData.order) {
        this.query.orderBy = sortData.prop;
        this.query.orderDirection = sortData.order === "ascending" ? "ASC" : "DESC";
      } else {
        this.query.orderBy = "";
        this.query.orderDirection = "";
      }
      this.loadData();
    },
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 1.17rem;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
